import * as React from "react"
import { Box, Button, Flex, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"

import { FeedTutorialItemFragment } from "lib/graphql"
import { getVideoId } from "lib/helpers/utils"

interface Props {
  tutorial: FeedTutorialItemFragment
}

export function FeedTutorial({ tutorial }: Props) {
  return (
    <LinkBox position="relative" overflow="hidden" _hover={{ button: { display: "block" } }}>
      <LinkOverlay href={`/tutorials/${tutorial.id}`} textDecoration="none" justifyContent="space-between">
        <Flex w="100%" flexDir={{ base: "column", md: "row" }}>
          <Box position="relative">
            <Image
              src={`https://img.youtube.com/vi/${getVideoId(tutorial.videoUrl)}/0.jpg`}
              h={{ base: "220px", md: "145px" }}
              w={{ base: "100%", md: "250px" }}
              objectFit="cover"
              objectPosition="center"
              alt="instructievideo"
              borderRadius="md"
              mr={8}
            />
            <Box
              position="absolute"
              top={{ base: "125px", md: "50%" }}
              transform="translate(-50%, -50%)"
              left={{ base: "50%", md: "125px" }}
            >
              <Button
                display="none"
                colorScheme="yellow"
                textTransform="uppercase"
                _hover={{ bg: "yellow.600" }}
              >
                Play
              </Button>
            </Box>
          </Box>
          <Flex
            py={3}
            flexDir="column"
            justifyContent="space-between"
            w={{ base: "100%", md: "calc(100% - 280px)" }}
          >
            <Text fontWeight="black" fontSize="xl" textTransform="uppercase">
              {tutorial.title && tutorial.title}
            </Text>
            <Text
              mb="auto"
              fontSize="xl"
              fontWeight="bold"
              color="gray.400"
              whiteSpace="nowrap"
              overflow="hidden"
              isTruncated
            >
              {tutorial.instructor && tutorial.instructor}
            </Text>
            <Flex justifyContent="space-between" w="100%" flexDir={{ base: "column", md: "row" }}>
              <Text
                fontWeight="bold"
                fontSize="xs"
                color="gray.400"
                display="flex"
                alignItems="center"
                mr={2}
              >
                <span
                  style={{
                    textTransform: "uppercase",
                    display: "inline-flex",
                    alignItems: "center",
                    paddingRight: "5px",
                  }}
                >
                  {tutorial.categories &&
                    `${tutorial.categories?.reduce((acc, i) => acc + i + " / ", "").slice(0, -2)}`}
                </span>
              </Text>
              {tutorial.difficultyRating && (
                <Flex alignItems="center">
                  <Text mr={4} color="gray.400" fontWeight={600} fontSize="xs">
                    Moeilijkheid:{" "}
                  </Text>
                  {Array.from(Array(5)).map((_, i) => {
                    return (
                      <Box
                        key={i}
                        borderRadius="50%"
                        height="8px"
                        width="8px"
                        background={
                          tutorial?.difficultyRating && tutorial?.difficultyRating >= i + 1
                            ? "yellow.500"
                            : "gray.400"
                        }
                        display="block"
                        marginRight="8px"
                      />
                    )
                  })}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  )
}
