import * as React from "react"
import { Badge, Flex, Link, Stack, Text } from "@chakra-ui/react"

import { AgendaItemFragment } from "lib/graphql"
import { transformImage } from "lib/helpers/utils"

interface ItemProps {
  item: AgendaItemFragment
}

export const AgendaItem: React.FC<ItemProps> = ({ item }) => {
  return (
    <Stack spacing={2} mb={4}>
      <Link
        href={item.type === "competition" ? `/wedstrijd/${item.slug}` : `/te-doen/${item.slug}`}
        w="100%"
        textDecoration="none"
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        _hover={{ textDecoration: "none" }}
        position="relative"
      >
        <Flex
          borderRadius="xl"
          height="100px"
          w="100%"
          bgImage={item?.image ? transformImage(item?.image, "h_650") : "event-placeholder.png"}
          bgSize="cover"
          bgPos="center"
          role="img"
          aria-label={item.type === "competition" ? "wedstrijd image" : "te doen image"}
          pos="relative"
          filter="brightness(60%)"
          p={2}
          alignItems="flex-end"
        />

        <Badge
          position="absolute"
          bg="yellow.500"
          color="black"
          top={2}
          right={2}
          textTransform="capitalize"
          borderRadius="md"
          fontSize="md"
          px={2}
        >
          {item.type === "competition" ? "wedstrijd" : "te doen"}
        </Badge>
        <Text
          position="absolute"
          bottom={2}
          left={2}
          color="white"
          fontSize={{ base: "md", md: "lg" }}
          fontWeight="bold"
          maxW="60%"
          noOfLines={2}
        >
          {item.name}
        </Text>
      </Link>
    </Stack>
  )
}
