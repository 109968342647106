import * as React from "react"
import { HStack, Link, Text } from "@chakra-ui/react"

import { Limiter } from "./Limiter"

export function TickerBar() {
  return (
    <HStack
      top={0}
      right={0}
      left={0}
      zIndex={100}
      bgColor="yellow.500"
      w="100%"
      py={2}
      as={Link}
      href="/wedstrijd"
      position="absolute"
    >
      <Limiter>
        <HStack justify="center">
          <Text fontWeight="bold" fontSize="lg" color="black">
            Meld je nu aan voor de voorrondes van Kunstbende 2025!
          </Text>
        </HStack>
      </Limiter>
    </HStack>
  )
}
