import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Flex, Image, LinkBox, LinkOverlay, Text, useColorModeValue } from "@chakra-ui/react"
import dayjs from "dayjs"

import { FeedEventItemFragment } from "lib/graphql"
import { transformImage } from "lib/helpers/utils"

const _ = gql`
  fragment FeedEventItem on Event {
    id
    name
    type
    region
    categories
    image
    date
    slug
    time
    location
    time
    maxAttendees
    attendeeCount
  }
`

interface Props {
  event: FeedEventItemFragment
}

export function FeedEventTile({ event }: Props) {
  const colorGray = useColorModeValue("gray.500", "gray.400")
  const remainingSpots = event?.maxAttendees && event?.maxAttendees - event?.attendeeCount

  return (
    <LinkBox position="relative" overflow="hidden">
      <LinkOverlay href={`/te-doen/${event.slug}`} textDecoration="none">
        <Image
          src={event?.image ? transformImage(event?.image, "h_650") : "event-placeholder.png"}
          h="190px"
          w="100%"
          objectFit="cover"
          objectPosition="center"
          alt="evenement afbeelding"
          borderRadius="md"
        />

        <Box width="100%" py={3} px={1}>
          <Text fontWeight="bold" fontSize="lg" textTransform="capitalize">
            {event.name}
          </Text>
          <Text fontSize="sm" color={colorGray} whiteSpace="nowrap" overflow="hidden" isTruncated>
            {event.type && `${event.type} / `} {event.region && `${event.region} / `}
            {event.categories?.reduce((acc, i) => acc + i + ", ", "").slice(0, -2)}
          </Text>

          <Text fontWeight="bold" fontSize="sm" color="yellow.400">
            {event.date && dayjs(event.date).format("DD MMM")}
            {event.time && ` @ ${event.location}`}
          </Text>
        </Box>
      </LinkOverlay>
      {remainingSpots && remainingSpots < 5 && (
        <Flex pos="absolute" top={0} left={0} w="100%" display="block" borderRadius="md">
          {remainingSpots === 1 ? (
            <Text
              fontWeight="bold"
              color="white"
              bg="pink.500"
              textAlign="center"
              fontSize="sm"
              py={1}
              borderRadius="md"
            >
              Nog {remainingSpots} plek beschikbaar
            </Text>
          ) : remainingSpots === 0 ? (
            <Text
              fontWeight="bold"
              color="white"
              bg="pink.500"
              textAlign="center"
              fontSize="sm"
              py={1}
              borderRadius="md"
            >
              Evenement vol
            </Text>
          ) : (
            <Text
              fontWeight="bold"
              color="white"
              bg="pink.500"
              textAlign="center"
              fontSize="sm"
              py={1}
              borderRadius="md"
            >
              Nog {remainingSpots} plekken beschikbaar
            </Text>
          )}
        </Flex>
      )}
    </LinkBox>
  )
}
