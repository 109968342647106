import * as React from "react"
import { Box, Button, Image, Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react"
import NextLink from "next/link"

export function Kollaborate() {
  const color = useColorModeValue("gray.700", "gray.400")
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Box
      height={{ base: "100%", md: "40rem", lg: "53rem" }}
      bg={{ base: "none", md: "url(/kollaborate-bg.png) no-repeat" }}
      bgSize={{ base: "none", md: "contain" }}
      bgPos={{ base: "none", md: "top right" }}
      pb={10}
    >
      <Stack h="75%" w={{ base: "100%", md: "45%" }} justifyContent="center">
        <Stack px={{ base: 6, md: 0 }} spacing={8}>
          <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={900} lineHeight="shorter" role="heading">
            Zoek collabs via{" "}
            <Text as="span" color="yellow.500" role="heading">
              Prikbord
            </Text>
          </Text>
          <Text
            color={color}
            fontSize={{ base: "lg", md: "2xl" }}
            lineHeight="short"
            textAlign={{ base: "left", md: "left" }}
            pr={{ base: 0, md: 32 }}
            role="heading"
          >
            Plaats een oproep op ons Prikbord, ontmoet andere makers en ga samen aan de slag. Check ook de
            verzoekjes voor optredens en opdrachten van onze partners!
          </Text>

          <Button
            as={NextLink}
            passHref
            href="/prikbord"
            fontSize={{ base: 16, md: 22 }}
            px={{ base: 8, md: 8 }}
            py={{ base: 6, md: 8 }}
            colorScheme="yellow"
            w="fit-content"
          >
            BEKIJK
          </Button>
        </Stack>
        {isMobile && <Image alt="afbeelding samenwerken" src="/kollaborate-bg-mobile.png" />}
      </Stack>
    </Box>
  )
}
