import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { HStack, IconButton, SimpleGrid, Stack, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { RecommendedConnectionItemFragment } from "lib/graphql"

import { RecommendedConnectionTile } from "./RecommendedConnectionTile"

interface Props {
  connections: RecommendedConnectionItemFragment[]
  connectionsCount: number
}

export function RecommendedConnectionsSlider({ connections, connectionsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 3

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 30,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <HStack
      id={isMobile ? "my-keen-slider-recommended-connections" : ""}
      className={isMobile ? "keen-slider" : ""}
      ref={isMobile ? sliderRef : null}
      position="relative"
    >
      {isMobile && connectionsCount > displayCount && (
        <>
          <IconButton
            zIndex={500}
            position="absolute"
            top="85px"
            left={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentIndex === 0}
            width="3rem"
          />
          <IconButton
            zIndex={500}
            position="absolute"
            top="85px"
            right={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            disabled={currentIndex + 1 === connectionsCount || currentIndex >= connectionsCount}
            width="3rem"
            ml={5}
          />
        </>
      )}
      §
      {isMobile ? (
        connections.map((connection) => (
          <HStack
            key={`recommended:${connection.id}`}
            className={isMobile ? "keen-slider__slide" : ""}
            flex={1}
            m={0}
            p={0}
            spacing={0}
          >
            <RecommendedConnectionTile connection={connection} />
          </HStack>
        ))
      ) : (
        <SimpleGrid columns={3} w="100%" gap={8}>
          {connections.map((connection) => (
            <Stack key={`recommended:${connection.id}`} flex={1} w="100%">
              <RecommendedConnectionTile connection={connection} />
            </Stack>
          ))}
        </SimpleGrid>
      )}
      )
    </HStack>
  )
}
