import * as React from "react"
import { gql } from "@apollo/client"

import { FeaturedProjectItemFragment } from "lib/graphql"

import { FeaturedProjectSlider } from "./FeaturedProjectSlider"

const _ = gql`
  fragment FeaturedProjectItem on FeaturedProject {
    id
    title
    image
    url
    description
    creator {
      id
      fullName
      avatarUrl
    }
    comments {
      count
    }
  }
`

interface Props {
  projects: FeaturedProjectItemFragment[]
}

export function FeedFeaturedProjects({ projects }: Props) {
  const count = projects.length
  if (projects.length === 0) return null
  return <FeaturedProjectSlider projects={projects} projectsCount={count} />
}
