import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { Box, Button, Flex, IconButton, Link, Stack, Text, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { LandingPageEventItemFragment } from "lib/graphql"

import { transformImage } from "../lib/helpers/utils"

interface Props {
  events: LandingPageEventItemFragment[]
  eventsCount: number
}

export function EventSlider({ events, eventsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 3

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 4,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <>
      <Box className="keen-slider" ref={sliderRef}>
        {events.map((event, i) => (
          <Stack key={i} cursor="pointer" px={5} pt={8} className="keen-slider__slide">
            <Link
              href={`/te-doen/${event.slug}`}
              w="100%"
              textDecoration="none"
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              _hover={{ textDecoration: "none" }}
            >
              <Box
                height="260px"
                w="100%"
                bgImage={event.image ? transformImage(event.image, "h_350") : "event-placeholder.png"}
                role="img"
                aria-label="te doen image"
                bgSize="cover"
                bgPos="center"
                borderRadius="2xl"
                mb={4}
              />
              <Text textAlign="left" color="white" fontSize={24} fontWeight="black" lineHeight="smaller">
                {event.name}
              </Text>
              <Text
                fontSize={16}
                color="gray.500"
                whiteSpace="nowrap"
                overflow="hidden"
                isTruncated
                maxW="100%"
              >
                {event.type && `${event.type} / `} {event.region && `${event.region} / `}
                {event.categories?.reduce((acc, i) => acc + i + ", ", "").slice(0, -2)}
              </Text>
            </Link>
          </Stack>
        ))}
      </Box>

      <Flex justify="center" pt={0} mt={2}>
        <IconButton
          colorScheme="gray"
          variant="ghost"
          aria-label="vorige dia"
          icon={<MdKeyboardArrowLeft size={48} />}
          onClick={(e) => {
            e.stopPropagation()
            slider.prev()
          }}
          disabled={currentIndex === 0}
          width="3rem"
          mr={5}
        />
        <Stack isInline alignItems="center" spacing={6}>
          {Array.from({
            length:
              eventsCount % displayCount > 0 && eventsCount === 9
                ? eventsCount / displayCount + 1
                : eventsCount / displayCount,
          }).map((_, i) => {
            const isActive = currentIndex === i * displayCount
            return (
              <Button
                key={i}
                size="xs"
                w="18px"
                h="18px"
                minH="18px"
                minW="18px"
                p={0}
                onClick={(e) => {
                  e.stopPropagation()
                  slider.moveToSlide(i * displayCount)
                }}
                borderRadius="50%"
                bgColor={isActive ? "pink.500" : "gray.300"}
                aria-label={`gebeurtenisdia ${i + 1}`}
              />
            )
          })}
        </Stack>
        <IconButton
          colorScheme="gray"
          variant="ghost"
          aria-label="volgende dia"
          icon={<MdKeyboardArrowRight size={48} />}
          onClick={(e) => {
            e.stopPropagation()
            slider.next()
          }}
          disabled={currentIndex + 1 === eventsCount || currentIndex >= eventsCount}
          width="3rem"
          ml={5}
        />
      </Flex>
    </>
  )
}
