import * as React from "react"
import { FaRegComments } from "react-icons/fa"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { Box, Flex, IconButton, Link, Text, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { LatestProjectItemFragment } from "lib/graphql"

import { LinkThumbnail } from "./ProjectTile"

interface Props {
  projects: LatestProjectItemFragment[]
  projectsCount: number
}

export function LatestProjectsSlider({ projects, projectsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 2

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 30,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <Box id="my-keen-slider-latest-projects" className="keen-slider" ref={sliderRef} position="relative">
      {projectsCount > displayCount && (
        <>
          <IconButton
            zIndex={500}
            position="absolute"
            top="75px"
            left={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentIndex === 0}
            width="3rem"
          />
          <IconButton
            zIndex={500}
            position="absolute"
            top="75px"
            right={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            disabled={currentIndex + 1 === projectsCount || currentIndex >= projectsCount}
            width="3rem"
            ml={5}
          />
        </>
      )}
      {projects.map((project) => (
        <SliderProjectTile key={project.id} project={project} />
      ))}
    </Box>
  )
}

interface SliderProjectProps {
  key: string
  project: LatestProjectItemFragment
}

function SliderProjectTile({ project }: SliderProjectProps) {
  return (
    <Box key={`latest:${project.id}`} cursor="pointer" className="keen-slider__slide">
      <Link
        href={`/users/${project.creator.id}/projecten/${project.id}`}
        w="100%"
        textDecoration="none"
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        _hover={{ textDecoration: "none" }}
      >
        <LinkThumbnail project={project} height="190px" />
        <Flex align="center" justifyContent="space-between" w="100%">
          <Text
            textAlign="left"
            color="white"
            fontSize={{ base: "lg", md: "xl" }}
            lineHeight="smaller"
            fontWeight="semibold"
            noOfLines={3}
            mr="auto"
          >
            {project.title}
          </Text>
          <Flex alignItems="center">
            <Text fontSize="lg">{project.comments.count}</Text>
            <Box as={FaRegComments} ml={2} boxSize="24px" color="gray.300" />
          </Flex>
        </Flex>
      </Link>
    </Box>
  )
}
