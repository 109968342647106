import * as React from "react"
import { Box, Image } from "@chakra-ui/react"

import { TickerBar } from "./TickerBar"

export function Hero() {
  const handleScrollDown = () => {
    window.scrollBy({ top: 700, left: 0, behavior: "smooth" })
  }

  return (
    <Box position="relative">
      <Image
        alt="scroll naar beneden knop"
        src="/hero-arrow.png"
        width="200px"
        height="200px"
        position="absolute"
        bottom={-24}
        ml="auto"
        mr="auto"
        right={0}
        left={0}
        onClick={handleScrollDown}
        cursor="pointer"
        zIndex={100}
      />

      <TickerBar />

      <Box h="85vh">
        <Box className="hero-iframe-container">
          <iframe
            src="https://player.vimeo.com/video/915929839?badge=0&player_id=0&app_id=58479&background=1"
            allow="autoplay"
            title="Welkom bij Kunstbende"
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
    </Box>
  )
}
