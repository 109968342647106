import * as React from "react"
import { GiMicrophone } from "react-icons/gi"
import { MdOutlineGroups } from "react-icons/md"
import { TbSchool } from "react-icons/tb"
import { Box, Center, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react"

export function Benefits() {
  const color = useColorModeValue("gray.700", "gray.400")
  return (
    <Center>
      <Stack spacing={10} textAlign="center" alignItems="center">
        <Stack spacing={{ base: 2, md: 0 }} alignItems="center" pb={8}>
          <Text
            as="h3"
            fontSize={{ base: "4xl", md: "4xl" }}
            fontWeight={900}
            px={{ base: 2, md: 48 }}
            lineHeight={{ base: "shorter", md: "short" }}
          >
            Meld je gratis aan en krijg deze voordelen
          </Text>
          <Text
            as="h4"
            color={color}
            fontSize={{ base: "xl", md: "2xl" }}
            px={{ base: 2, md: 32 }}
            lineHeight="short"
          >
            Kunstbende is de snelste weg naar een upgrade van jouw creatieve skills.
          </Text>
        </Stack>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Stack spacing={4} alignItems="center">
            <Box as={TbSchool} size={90} />
            <Text fontWeight={900} fontSize="2xl" as="h5">
              Leer nieuwe skills waar en wanneer jij wil
            </Text>
            <Text fontSize="md" color={color}>
              Met jouw profiel heb je toegang tot tutorials, exclusieve trips naar festivals (ook achter de
              schermen), workshops, coaching en meet-ups. Allemaal gratis en in het hele land.
            </Text>
          </Stack>
          <Stack spacing={4} alignItems="center">
            <Box as={GiMicrophone} size={90} />
            <Text fontWeight={900} fontSize="2xl" as="h5">
              Bouw snel podiumervaring op
            </Text>
            <Text fontSize="md" color={color}>
              Laat het podiumbeest in je los op de voorronde bij jou in de buurt! Ook voor als je het nog
              doodeng vindt. Inclusief jury voor opbouwende feedback.
            </Text>
          </Stack>
          <Stack spacing={4} alignItems="center">
            <Box as={MdOutlineGroups} size={90} />
            <Text fontWeight={900} fontSize="2xl" as="h5">
              Krijg toegang tot ons netwerk van 5000+ members
            </Text>
            <Text fontSize="md" color={color}>
              Samen dansen, tekenen of wat dan ook is veel leuker dan alleen, toch? Connect met andere members
              en ga samen aan de slag met nieuwe projecten.
            </Text>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Center>
  )
}
