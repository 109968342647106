import * as React from "react"
import { Avatar, Box, Button, LinkBox, LinkOverlay, Stack, Text } from "@chakra-ui/react"

import {
  GetUserFeedDocument,
  RecommendedConnectionItemFragment,
  useRequestConnectionMutation,
} from "lib/graphql"
import { useMe } from "lib/hooks/useMe"
import { useMutationHandler } from "lib/hooks/useMutationHandler"

interface ConnectionProps {
  connection: RecommendedConnectionItemFragment
}

export const RecommendedConnectionTile: React.FC<ConnectionProps> = ({ connection }) => {
  const { me } = useMe()
  const handler = useMutationHandler()
  const [requestConnect, { loading: requestLoading }] = useRequestConnectionMutation({
    refetchQueries: [{ query: GetUserFeedDocument, variables: { id: me?.id } }],
  })

  const handleRequestConnect = () => {
    return handler(() => requestConnect({ variables: { userId: connection.id } }), {
      onSuccess: (_, toast) => toast({ description: "Verzoek verstuurd" }),
    })
  }
  return (
    <Stack
      w="100%"
      p={4}
      borderRadius="xl"
      bgColor="gray.700"
      alignItems="center"
      justifyContent="space-between"
      textAlign="center"
      marginInline={0}
    >
      <LinkBox position="relative" overflow="hidden" flex={1} alignItems="center">
        <LinkOverlay href={`/users/${connection.customSlug || connection.id}`} textDecoration="none">
          <Avatar
            src={connection.avatarUrl || undefined}
            size="lg"
            bg="gray.600"
            name={connection.firstName || "avatar"}
          />
          <Text fontSize="xl" fontWeight="semibold">
            {connection.firstName}
          </Text>
          <Text fontSize="sm" color="gray.300" w={{ base: "80%" }}>
            {connection.interests.map(
              (interest, index) => `${interest}${connection.interests.length - 1 !== index ? " / " : ""}`,
            )}
          </Text>
        </LinkOverlay>
      </LinkBox>
      <Box pt={2} mt="auto">
        <Button
          size="sm"
          variant="outline"
          colorScheme="yellow"
          isLoading={requestLoading}
          isDisabled={requestLoading}
          onClick={handleRequestConnect}
        >
          Connect
        </Button>
      </Box>
    </Stack>
  )
}
