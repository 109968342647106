import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Text } from "@chakra-ui/react"

import { LatestProjectItemFragment } from "lib/graphql"

import { LatestProjectsSlider } from "./LatestProjectsSlider"

const _ = gql`
  fragment LatestProjectItem on Project {
    id
    title
    image
    url
    creator {
      id
    }
    comments {
      count
    }
  }
`

interface Props {
  projects: LatestProjectItemFragment[]
}

export function FeedLatestProjects({ projects }: Props) {
  if (projects.length === 0) return null
  return (
    <Box>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black" mb={4}>
        Trending projecten voor jou
      </Text>
      <LatestProjectsSlider projects={projects} projectsCount={projects.length} />
    </Box>
  )
}
