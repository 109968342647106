import type { ButtonProps } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
import NextLink from "next/link"
import * as React from "react"

export interface LinkButtonProps extends ButtonProps {
  href: string
  isExternal?: boolean
  target?: "_blank"
  download?: string
}
export function LinkButton({ href, isExternal, ...props }: LinkButtonProps) {
  return (
    <Button
      as={NextLink}
      href={href}
      textDecor="none !important"
      target={isExternal ? "_blank" : undefined}
      {...props}
    >
      {props.children}
    </Button>
  )
}
