import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Flex, Stack, Text } from "@chakra-ui/react"
import dayjs from "dayjs"

import { AgendaItemFragment } from "lib/graphql"

import { AgendaItem } from "./AgendaItem"
import { LinkButton } from "./LinkButton"

const _ = gql`
  fragment AgendaItem on AgendaItem {
    name
    date
    type
    slug
    image
  }
`

interface Props {
  agenda: AgendaItemFragment[]
}

export function Agenda({ agenda }: Props) {
  const groupedItems = React.useMemo(
    () =>
      agenda?.reduce((acc, item) => {
        if (acc[item.date]) {
          acc[item.date].push(item)
        } else {
          acc[item.date] = [item]
        }
        return acc
      }, {} as { [date: string]: AgendaItemFragment[] }),
    [agenda],
  )

  return (
    <Stack
      spacing={2}
      py={6}
      px={4}
      top={28}
      maxH="80vh"
      w={{ base: "100%", md: "100%" }}
      position={{ base: "static", md: "sticky" }}
      bgColor="gray.700"
      borderRadius="xl"
      right={{ base: 6, md: 20, lg: 28 }}
      overflowY="auto"
      zIndex={50}
      borderTop="solid 14px yellow"
      borderColor="yellow.400"
    >
      <Text color="yellow.400" fontSize="lg" fontWeight="black" m={0}>
        Dit komt eraan voor jou!
      </Text>
      {agenda.length > 0 && groupedItems ? (
        Object.entries(groupedItems).map(([date, items], i) => {
          return (
            <Box key={date}>
              <Flex m={0} w="100%" justify="space-between" align="flex-end" mb={1} mt={4}>
                <Text
                  color="gray.200"
                  fontWeight="black"
                  textTransform="capitalize"
                  fontSize={{ base: "xl", md: "xl" }}
                >
                  {dayjs(date).format("MMMM")}
                </Text>
              </Flex>
              <Stack spacing={4}>
                <Text mb={0} textTransform="capitalize" fontSize="sm" color="gray.400">
                  {dayjs(date).format("dddd D")}
                </Text>
                {items.map((item) => (
                  <AgendaItem item={item} key={i} />
                ))}
              </Stack>
            </Box>
          )
        })
      ) : (
        <Stack spacing={4}>
          <Text color="white" fontSize={{ base: "sm", md: "md" }}>
            Je agenda is nog leeg. Tijd om ‘m te vullen! 😊
          </Text>
          <LinkButton variant="outline" href="/te-doen" size="md" colorScheme="yellow">
            Leuke dingen om te doen
          </LinkButton>
        </Stack>
      )}
    </Stack>
  )
}
