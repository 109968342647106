import * as React from "react"
import { Center, Flex, Image, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react"

export function Partners() {
  const color = useColorModeValue("gray.700", "gray.400")
  return (
    <Center>
      <Stack spacing={6} alignItems="center">
        <Text fontSize={{ base: 32, md: 48 }} fontWeight={900} lineHeight="shorter" role="heading">
          Onze partners
        </Text>
        <Text
          px={{ base: 0, md: 48 }}
          color={color}
          fontSize={{ base: "lg", md: "2xl" }}
          lineHeight="short"
          role="heading"
        >
          Kunstbende wordt mogelijk gemaakt door
        </Text>
        <Stack spacing={8} pt={8}>
          <Flex align="center" justify="center">
            <Image w={{ base: "60%", md: "30%" }} alt="rabobank partnerlogo" src="/rabo.svg" />
          </Flex>
          <Center>
            <SimpleGrid w={{ base: "70%", md: "60%" }} columns={{ base: 2, md: 3 }} spacing={10}>
              <Flex align="center" justify="center">
                <Image alt="fcp partnerlogo" src="/fcp_logo.png" />
              </Flex>
              <Flex align="center" justify="center">
                <Image alt="ArtEZ partnerlogo" src="/ArtEZ_logo.png" />
              </Flex>
              <Flex align="center" justify="center">
                <Image alt="cjp partnerlogo" src="/cjp_logo.png" />
              </Flex>
            </SimpleGrid>
          </Center>
        </Stack>
      </Stack>
    </Center>
  )
}
