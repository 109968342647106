import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Button, Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react"
import NextLink from "next/link"

import { useGetLandingPageEventsQuery } from "lib/graphql"

import { EventSlider } from "./EventSlider"

const _ = gql`
  fragment LandingPageEventItem on Event {
    id
    name
    type
    image
    region
    categories
    slug
  }

  query GetLandingPageEvents(
    $take: Int
    $orderBy: [EventOrderByWithRelationInput!]
    $where: EventWhereInput
    $skip: Int
  ) {
    events(take: $take, orderBy: $orderBy, where: $where, skip: $skip) {
      items {
        ...LandingPageEventItem
      }
      count
    }
  }
`

export function Klasses() {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const color = useColorModeValue("gray.700", "gray.400")

  const { data } = useGetLandingPageEventsQuery({
    variables: {
      take: isMobile ? 3 : 9,
      where: {
        isFeatured: { equals: true },
      },
    },
  })
  const count = data?.events.items.length

  return (
    <Stack align="center">
      <Text
        fontSize={{ base: 32, md: 48 }}
        fontWeight={900}
        lineHeight="shorter"
        px={{ base: 6, md: 0 }}
        textAlign={{ base: "left", md: "center" }}
        role="heading"
      >
        <Text as="span" color="yellow.500" role="heading">
          Workshops
        </Text>{" "}
        om jou te inspireren
      </Text>
      <Text
        color={color}
        mt={4}
        textAlign={{ base: "left", md: "center" }}
        px={{ base: 6, md: "260px" }}
        fontSize={{ base: "lg", md: "2xl" }}
        lineHeight="short"
        role="heading"
      >
        Volg onze gratis workshops en masterclasses! Ze worden in het hele land georganiseerd en soms ook
        online. Dus er is altijd iets bij jou in de buurt. Hier helpen professionals jou op weg. En je leert
        andere jonge makers kennen.
      </Text>
      <Box pt={6}>
        <Button
          as={NextLink}
          passHref
          href="/te-doen"
          fontSize={{ base: 16, md: 22 }}
          px={{ base: 8, md: 8 }}
          py={{ base: 6, md: 8 }}
          colorScheme="yellow"
          w="fit-content"
        >
          GA NAAR WORKSHOPS
        </Button>
      </Box>

      {data && data?.events?.items.length > 0 && count && (
        <EventSlider events={data.events.items} eventsCount={count} />
      )}
    </Stack>
  )
}
