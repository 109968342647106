import * as React from "react"
import { gql } from "@apollo/client"
import { Button, Center, Link, Stack, Text } from "@chakra-ui/react"

import { FeedTutorialItemFragment } from "lib/graphql"

import { FeedTutorial } from "./FeedTutorial"

const _ = gql`
  fragment FeedTutorialItem on Tutorial {
    id
    videoUrl
    title
    duration
    instructor
    categories
    isLive
    createdAt
    difficultyRating
  }
`

interface Props {
  tutorials: FeedTutorialItemFragment[]
}

export function FeedRecommendedTutorials({ tutorials }: Props) {
  if (tutorials.length === 0) return null
  return (
    <Stack spacing={4}>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black" mb={4}>
        Tutorials
      </Text>
      {tutorials.map((tutorial) => {
        return <FeedTutorial key={tutorial.id} tutorial={tutorial} />
      })}
      <Center>
        <Button as={Link} href="/tutorials" colorScheme="yellow" variant="outline">
          View All
        </Button>
      </Center>
    </Stack>
  )
}
