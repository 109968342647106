import * as React from "react"
import { gql } from "@apollo/client"
import { AspectRatio, Box, Flex, FlexProps, Image, Stack, Text } from "@chakra-ui/react"

import { CustomFeedFragment } from "lib/graphql"
import { parseUrl } from "lib/helpers/utils"

import { LinkButton } from "./LinkButton"

const _ = gql`
  fragment CustomFeed on CustomFeed {
    id
    heading
    text
    buttonUrl
    buttonText
    video
    image
  }
`

interface Props extends FlexProps {
  customFeed?: CustomFeedFragment | null
}

export function CustomFeed({ customFeed, ...props }: Props) {
  if (!customFeed) return null

  return customFeed.video ? (
    <Box
      w="100%"
      borderLeft={{ base: "solid 10px yellow", md: "solid 14px yellow" }}
      borderRadius="xl"
      borderColor="yellow.400"
      h="fit-content"
      {...props}
    >
      <AspectRatio
        _before={{ height: 0, maxHeight: 200, content: '""', display: "block", paddingBottom: "55%" }}
      >
        <iframe
          title={customFeed.heading}
          src={parseUrl(customFeed.video)}
          allowFullScreen
          frameBorder="0"
          className="iframe-height-restricted"
        />
      </AspectRatio>
    </Box>
  ) : (
    <Flex
      w="100%"
      p={4}
      borderLeft={{ base: "solid 10px yellow", md: "solid 14px yellow" }}
      bgColor="gray.700"
      borderRadius="xl"
      borderColor="yellow.400"
      justify="space-between"
      flexDir={{ base: "column", md: "row" }}
      {...props}
      display="flex"
    >
      <Flex
        order={{ base: 2, md: 0 }}
        flexDir="column"
        align="space-around"
        flex={1}
        mr={6}
        w="100%"
        h="100%"
      >
        <Stack spacing={4}>
          <Text color="white" fontSize="lg" fontWeight="black" m={0}>
            {customFeed.heading}
          </Text>
          <Text color="white">{customFeed.text}</Text>
        </Stack>
        {customFeed.buttonText && customFeed.buttonUrl && (
          <Flex pt={4} mt="auto">
            <LinkButton variant="outline" href={customFeed.buttonUrl} size="sm" colorScheme="yellow">
              {customFeed.buttonText}
            </LinkButton>
          </Flex>
        )}
      </Flex>
      {customFeed.image && (
        <Image
          mb={{ base: 4, md: 0 }}
          order={{ base: -1, md: 1 }}
          textAlign="right"
          borderRadius="md"
          flex={1}
          alt="aankondiging afbeelding"
          objectFit="cover"
          maxH="200px"
          w={{ base: "100%", md: undefined }}
          src={customFeed.image}
        />
      )}
    </Flex>
  )
}
