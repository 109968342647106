import * as React from "react"
import { Box, Text } from "@chakra-ui/react"

import { EventItemFragment } from "lib/graphql"

import { RecommendedEventsSlider } from "./RecommendedEventsSlider"

interface Props {
  events: EventItemFragment[]
}

export function FeedRecommendedEvents({ events }: Props) {
  if (events.length === 0) return null
  return (
    <Box>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black" mb={4}>
        Dingen om te doen
      </Text>
      <RecommendedEventsSlider events={events} eventsCount={events.length} />
    </Box>
  )
}
