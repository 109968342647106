import * as React from "react"
import { Box, Button, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import NextLink from "next/link"

export function Kourses() {
  const color = useColorModeValue("gray.700", "gray.400")
  return (
    <Flex h={{ base: "auto", md: "100vh" }} align="center" flexDir={{ base: "column", md: "row" }}>
      <Stack
        spacing={10}
        mr={{ base: 0, md: 12 }}
        w={{ base: "100%", md: "55%" }}
        pr={{ base: 6, md: 0 }}
        pb={{ base: 0, md: 12 }}
      >
        <Text fontSize={{ base: 32, md: 48 }} fontWeight={900} lineHeight="shorter" role="heading">
          <Text as="span" color="yellow.500" role="heading">
            Tutorials
          </Text>{" "}
          om jou op weg te helpen
        </Text>
        <Text color={color} fontSize={{ base: "lg", md: "2xl" }} lineHeight="short" role="heading">
          Onze coaches hebben een tof aanbod voor jou dat je gratis kunt volgen. Je kunt deze tutorials op elk
          moment van de dag volgen.
        </Text>
        <Button
          as={NextLink}
          passHref
          href="/tutorials"
          fontSize={{ base: 16, md: 22 }}
          px={{ base: 8, md: 8 }}
          py={{ base: 6, md: 8 }}
          colorScheme="yellow"
          w="fit-content"
        >
          BEKIJK
        </Button>
      </Stack>

      <Box
        mt={{ base: 8, md: 0 }}
        w="100%"
        h={{ base: "565px", md: "90%" }}
        bg="url(/KrashKourse.png) no-repeat"
        bgSize={{ base: "cover", md: "contain" }}
        bgPos={{ base: "top left", md: "right" }}
      />
    </Flex>
  )
}
