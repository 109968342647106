import * as React from "react"
import { Box, Text } from "@chakra-ui/react"

import { PostItemFragment } from "lib/graphql"

import { RecommendedPostsSlider } from "./RecommendedPostsSlider"

interface Props {
  posts: PostItemFragment[]
}

export function FeedRecommendedPosts({ posts }: Props) {
  if (posts.length === 0) return null
  return (
    <Box>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black" mb={4}>
        De nieuwste prikbordberichten
      </Text>
      <RecommendedPostsSlider posts={posts} postsCount={posts.length} />
    </Box>
  )
}
