import * as React from "react"
import { gql } from "@apollo/client"
import {
  Box,
  Grid,
  GridItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import Head from "next/head"

import { useGetUserFeedQuery } from "lib/graphql"
import { useMe } from "lib/hooks/useMe"

import { Agenda } from "./Agenda"
import { CustomFeed } from "./FeedCustom"
import { FeedFeaturedProjects } from "./FeedFeaturedProjects"
import { FeedLatestProjects } from "./FeedLatestProjects"
import { FeedRecommendedConnections } from "./FeedRecommendedConnections"
import { FeedRecommendedEvents } from "./FeedRecommendedEvents"
import { FeedRecommendedPosts } from "./FeedRecommendedPosts"
import { FeedRecommendedTutorials } from "./FeedRecommendedTutorials"
import { TickerBar } from "./TickerBar"

const _ = gql`
  query GetUserFeed(
    $id: String!
    $take: Int
    $orderBy: [ProjectOrderByWithRelationInput!]
    $where: ProjectWhereInput
    $skip: Int
  ) {
    user(id: $id) {
      ...UserProfile
    }
    featuredProjects(take: $take, orderBy: $orderBy, where: $where, skip: $skip) {
      items {
        ...FeaturedProjectItem
      }
      count
    }
    customFeed {
      ...CustomFeed
    }
  }
`

export default function Feed() {
  const { me } = useMe()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { data } = useGetUserFeedQuery({
    variables: { id: me?.id as string, take: isMobile ? 3 : 9 },
  })

  if (!me) return null

  return (
    <Box pos="relative">
      <Head>
        <title>Kunstbende</title>
      </Head>

      <TickerBar />

      <Stack px={{ base: 6, md: 20, lg: 28 }} pt={12} pb={{ base: 6, md: 0 }} maxW="100%">
        <Text
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight={900}
          lineHeight={{ base: "shorter", md: "short" }}
        >
          Hi {me?.firstName}!
        </Text>
        <Text fontSize={{ base: "xl", md: "2xl" }}>Welkom op je eigen feed. Laat je inspireren!</Text>
      </Stack>
      {isMobile ? (
        <Tabs isLazy w="100%" px={6} pt={2}>
          <TabList borderBottom="none" w="100%">
            <Tab
              w="50%"
              fontWeight="black"
              color="white"
              fontSize="sm"
              paddingInlineStart={{ base: 1, sm: 2, md: 4 }}
              paddingInlineEnd={{ base: 1, sm: 2, md: 4 }}
              _selected={{ color: "yellow.500", borderColor: "yellow.500", borderBottomWidth: 4 }}
            >
              Feed
            </Tab>
            <Tab
              w="50%"
              fontWeight="black"
              color="white"
              fontSize="sm"
              paddingInlineStart={{ base: 1, sm: 2, md: 4 }}
              paddingInlineEnd={{ base: 1, sm: 2, md: 4 }}
              _selected={{ color: "yellow.500", borderColor: "yellow.500", borderBottomWidth: 4 }}
            >
              Agenda
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={8} py={2}>
                {data?.customFeed && <CustomFeed customFeed={data?.customFeed} />}
                <FeedFeaturedProjects projects={data?.featuredProjects.items || []} />
                <FeedLatestProjects projects={data?.user?.connectionProjects.items || []} />
                <FeedRecommendedConnections connections={data?.user?.recommendedConnections || []} />
                <FeedRecommendedEvents events={data?.user?.recommendedEvents || []} />
                <FeedRecommendedPosts posts={data?.user?.recommendedPosts.items || []} />
                <FeedRecommendedTutorials tutorials={data?.user?.recommendedTutorials || []} />
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing={8} py={2}>
                <Agenda agenda={data?.user?.agenda || []} />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Grid
          px={{ base: 6, md: 20, lg: 28 }}
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(5, 1fr)" }}
          gap={{ base: 0, md: 16 }}
          pb={120}
        >
          <GridItem colSpan={3} order={{ base: 2, md: 1 }} overflow="hidden">
            <Stack spacing={12} py={12}>
              {data?.customFeed && <CustomFeed customFeed={data?.customFeed} />}
              <FeedFeaturedProjects projects={data?.featuredProjects.items || []} />
              <FeedLatestProjects projects={data?.user?.connectionProjects.items || []} />
              <FeedRecommendedConnections connections={data?.user?.recommendedConnections || []} />
              <FeedRecommendedEvents events={data?.user?.recommendedEvents || []} />
              <FeedRecommendedPosts posts={data?.user?.recommendedPosts.items || []} />
              <FeedRecommendedTutorials tutorials={data?.user?.recommendedTutorials || []} />
            </Stack>
          </GridItem>
          <GridItem colSpan={2} order={{ base: 1, md: 2 }}>
            <Agenda agenda={data?.user?.agenda || []} />
          </GridItem>
        </Grid>
      )}
    </Box>
  )
}
