import * as React from "react"
import { FaRegComments, FaUser } from "react-icons/fa"
import { gql } from "@apollo/client"
import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import dayjs from "dayjs"

import { PostItemFragment } from "lib/graphql"
import { useMe } from "lib/hooks/useMe"

const _ = gql`
  fragment FeedPostItem on Post {
    id
    title
    description
    categories
    region
    deadline
    type
    videoUrl
    user {
      fullName
      avatarUrl
    }
    matchedUsers {
      count
    }
    createdAt
    comments {
      count
    }
  }
`

interface Props {
  post: PostItemFragment
  isRecommended?: boolean
}

export function FeedPostTile({ post, isRecommended }: Props) {
  const { me } = useMe()
  const color = useColorModeValue("black", "white")

  const matched = post.matchedUsers.count > 0

  return (
    <LinkBox position="relative" overflow="hidden">
      <LinkOverlay href={`/prikbord/${post.id}`} textDecoration="none">
        <Box width="100%" p={4} borderRadius="xl" bgColor="gray.700">
          <HStack justify="space-between" align="flex-start">
            <VStack align="flex-start">
              <Badge
                bg={post.type === "LOOKING" ? "pink.500" : "orange.500"}
                color="white"
                fontSize={16}
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {post.type === "LOOKING" ? "Ik zoek" : "Ik bied"}
              </Badge>
              {matched && (
                <Badge fontSize={16} bg="blue.500" color="white" whiteSpace="nowrap" overflow="hidden">
                  MIJN MATCHES
                </Badge>
              )}
            </VStack>
            {!isRecommended &&
              me &&
              me?.interests.filter((interest) => post.categories.includes(interest)).length > 0 && (
                <Box>
                  <Badge bg="yellow.500" color="black" fontSize="md" whiteSpace="nowrap" overflow="hidden">
                    Iets voor jou?
                  </Badge>
                </Box>
              )}
          </HStack>

          <Text
            mt={4}
            color={color}
            lineHeight="shorter"
            fontWeight="black"
            fontSize="2xl"
            textTransform="uppercase"
          >
            {post.title}
          </Text>
          <Text mt={4} fontWeight="bold" fontSize="sm" color="gray.300">
            Posted: {post.createdAt && dayjs(post.createdAt).format("DD MMMM")}
          </Text>
          <Text mt={0} fontWeight="bold" fontSize="sm" color="gray.300">
            Deadline: {post.deadline && dayjs(post.deadline).format("DD MMMM")}
          </Text>

          <Flex mt={6} align="center" justifyContent="space-between">
            <Flex>
              <Avatar
                bg="gray.600"
                icon={<FaUser color="gray.900" size={18} />}
                size="sm"
                src={post.user.avatarUrl || undefined}
                name={post.user.fullName || "avatar"}
                mr={3}
              />
              <Text color="gray.300" fontWeight="bold" fontSize="sm" my={0}>
                {post.user.fullName}
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Text fontSize="lg">{post.comments.count}</Text>
              <Box as={FaRegComments} ml={2} boxSize="24px" color="gray.300" />
            </Flex>
          </Flex>
        </Box>
      </LinkOverlay>
    </LinkBox>
  )
}
