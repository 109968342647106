import * as React from "react"
import { FaRegComments } from "react-icons/fa"
import { FaUser } from "react-icons/fa"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { FeaturedProjectItemFragment } from "lib/graphql"
import { transformImage } from "lib/helpers/utils"

import { LinkThumbnail } from "./ProjectTile"

interface Props {
  projects: FeaturedProjectItemFragment[]
  projectsCount: number
}

export function FeaturedProjectSlider({ projects, projectsCount }: Props) {
  const [, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = 1

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 0,
    loop: true,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
    duration: 1000,
    dragStart: () => {
      autoplay(false)
    },
    dragEnd: () => {
      autoplay(true)
    },
  })

  const autoplay = React.useCallback(
    (run: boolean) => {
      let interval: any = 0
      clearInterval(interval)
      interval = setInterval(() => {
        if (run && slider) {
          slider.next()
        }
      }, 10000)
    },
    [slider],
  )

  React.useEffect(() => {
    setCurrentIndex(0)
    if (slider) {
      autoplay(true)
    }
  }, [isMobile, autoplay, slider])

  return (
    <Box id="my-keen-slider-featured-project" className="keen-slider" ref={sliderRef} position="relative">
      {projectsCount > displayCount && (
        <>
          <IconButton
            zIndex={500}
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            // disabled={currentIndex === 0}
            width="3rem"
          />
          <IconButton
            zIndex={500}
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            right={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            // disabled={currentIndex + 1 === projectsCount || currentIndex >= projectsCount}
            width="3rem"
            ml={5}
          />
        </>
      )}
      {projects.map((project) => (
        <FeaturedSliderProjectTile key={project.id} project={project} />
      ))}
    </Box>
  )
}

interface FeaturedSliderProjectProps {
  key: string
  project: FeaturedProjectItemFragment
}

function FeaturedSliderProjectTile({ project }: FeaturedSliderProjectProps) {
  const avatar = project.creator.avatarUrl && transformImage(project.creator.avatarUrl, "w_100")
  return (
    <Box key={`featured:${project.id}`} cursor="pointer" className="keen-slider__slide">
      <Link
        href={`/users/${project.creator.id}/projecten/${project.id}`}
        w="100%"
        textDecoration="none"
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        _hover={{ textDecoration: "none" }}
      >
        <Box w="100%" filter="brightness(0.7)">
          <LinkThumbnail project={project} height="320px" />
        </Box>

        <Badge
          position="absolute"
          top={4}
          left={4}
          fontSize={{ base: "sm", md: "md" }}
          bgColor="yellow.500"
          color="black"
          px={3}
          py={1}
          borderRadius="lg"
          textTransform="capitalize"
        >
          Staff Pick
        </Badge>

        <Stack position="absolute" spacing={2} bottom={8} left={4} w="100%">
          <Text
            textAlign="left"
            color="white"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="black"
            lineHeight="smaller"
          >
            {project.title}
          </Text>
          <HStack>
            <Avatar
              src={avatar || undefined}
              name={project.creator.fullName || "avatar"}
              bg="gray.600"
              size="sm"
              icon={<FaUser color="gray.900" size={18} />}
            />
            <Text fontSize={{ base: "sm", md: "lg" }}>{project.creator.fullName}</Text>
          </HStack>
          <Flex alignItems="center" pos="absolute" bottom={0} right={8}>
            <Text fontSize="lg">{project.comments.count}</Text>
            <Box as={FaRegComments} ml={2} boxSize="24px" color="gray.300" />
          </Flex>
        </Stack>
      </Link>
    </Box>
  )
}
