import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Button, Flex, Link, Stack, Text, useBreakpointValue } from "@chakra-ui/react"

import { useGetLandingPageProjectsQuery } from "lib/graphql"

import { ProjectSlider } from "./ProjectSlider"

const _ = gql`
  fragment LandingPageProjectItem on FeaturedProject {
    id
    title
    image
    url
    creator {
      id
      fullName
      avatarUrl
    }
  }

  query GetLandingPageProjects(
    $take: Int
    $orderBy: [ProjectOrderByWithRelationInput!]
    $where: ProjectWhereInput
    $skip: Int
  ) {
    featuredProjects(take: $take, orderBy: $orderBy, where: $where, skip: $skip) {
      items {
        ...LandingPageProjectItem
      }
      count
    }
  }
`

export function FeaturedProjects() {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { data } = useGetLandingPageProjectsQuery({
    variables: {
      take: isMobile ? 3 : 9,
    },
  })
  const count = data?.featuredProjects.items.length

  return (
    <Stack spacing={8}>
      <Text
        fontSize={{ base: 32, md: 48 }}
        fontWeight={900}
        lineHeight="shorter"
        px={{ base: 6, md: 0 }}
        textAlign={{ base: "left", md: "center" }}
        as="h2"
      >
        <Text as="span" color="yellow.500">
          Nieuwe{" "}
          <Text as="span" color="white">
            projecten
          </Text>
        </Text>
      </Text>
      {/* <Text
        color={color}
        mt={4}
        textAlign={{ base: "left", md: "center" }}
        px={{ base: 6, md: "260px" }}
        fontSize={{ base: "lg", md: "2xl" }}
        lineHeight="short"
      >
        Some text about projects being great
      </Text> */}
      <Box pt={8}>
        {data && data?.featuredProjects?.items.length > 0 && count && (
          <ProjectSlider projects={data.featuredProjects.items} projectsCount={count} />
        )}
        <Flex justify="flex-end" mt={4}>
          <Button as={Link} href="/projecten" variant="outline" color="yellow">
            Zie alles
          </Button>
        </Flex>
      </Box>
    </Stack>
  )
}
