import * as React from "react"
import { gql } from "@apollo/client"
import { Avatar, Box, HStack, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"

import {
  FeaturedProjectItemFragment,
  LandingPageProjectItemFragment,
  ProfileProjectFragment,
  ProjectItemFragment,
} from "lib/graphql"
import { getVideoId, parseSpotifyURL, transformImage } from "lib/helpers/utils"

const _ = gql`
  fragment ProjectItem on Project {
    id
    title
    image
    url
    creator {
      id
      fullName
      avatarUrl
    }
  }
`

interface Props {
  project: ProjectItemFragment | LandingPageProjectItemFragment
}

export function ProjectTile({ project }: Props) {
  const avatar = project.creator.avatarUrl && transformImage(project.creator.avatarUrl, "w_100")
  return (
    <LinkBox position="relative" overflow="hidden">
      <LinkOverlay
        href={`/users/${project.creator.id}/projecten/${project.id}`}
        textDecoration="none"
        w="100%"
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        _hover={{ textDecoration: "none" }}
      >
        <LinkThumbnail project={project} />
        <Text
          textAlign="left"
          color="white"
          fontSize={{ base: "lg", md: "2xl" }}
          lineHeight="smaller"
          fontWeight="semibold"
        >
          {project.title}
        </Text>
        <HStack mt={2}>
          <Avatar src={avatar || undefined} size="xs" name={project.creator.fullName || "avatar"} />
          <Text fontSize="sm" fontWeight="bold" color="gray.400">
            {project.creator.fullName}
          </Text>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  )
}

export function LinkThumbnail({
  project: { id, url, image },
  height,
}: {
  project:
    | ProjectItemFragment
    | LandingPageProjectItemFragment
    | ProfileProjectFragment
    | FeaturedProjectItemFragment
  height?: string
}) {
  const { data } = useQuery([`stream${id}`], () =>
    url && url.includes("mixcloud")
      ? fetch(`https://api.${url.substring(url.indexOf("mixcloud"))}`).then((res) => res.json())
      : url && url.includes("spotify")
      ? fetch(parseSpotifyURL(url)).then((res) => res.json())
      : url &&
        url.includes("vimeo") &&
        fetch(`https://vimeo.com/api/oembed.json?url=${url}&width=960&height=720`).then((res) => res.json()),
  )

  return (
    <Box
      borderRadius="md"
      height={height ? height : "220px"}
      w="100%"
      bgImage={
        image
          ? transformImage(image, "h_300")
          : url?.includes("youtu")
          ? `https://img.youtube.com/vi/${getVideoId(url)}/0.jpg`
          : data?.thumbnail_url
          ? data.thumbnail_url
          : url && url.includes("mixcloud")
          ? data?.pictures["640wx640h"]
          : url && url.includes("spotify")
          ? data?.thumbnail_url
          : "/project-placeholder.png"
      }
      role="img"
      aria-label="project image"
      bgSize="cover"
      bgPos="center"
      mb={4}
    />
  )
}
