import * as React from "react"
import { gql } from "@apollo/client"
import { Box, Text } from "@chakra-ui/react"

import { RecommendedConnectionItemFragment } from "lib/graphql"

import { RecommendedConnectionsSlider } from "./RecommendedConnectionsSlider"

const _ = gql`
  fragment RecommendedConnectionItem on User {
    id
    firstName
    interests
    avatarUrl
    customSlug
  }
`

interface Props {
  connections: RecommendedConnectionItemFragment[]
}

export function FeedRecommendedConnections({ connections }: Props) {
  if (connections.length === 0) return null

  return (
    <Box>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black" mb={4}>
        Connect om elkaars projecten te zien!
      </Text>
      <RecommendedConnectionsSlider connections={connections} connectionsCount={connections.length} />
    </Box>
  )
}
