import * as React from "react"
import { Center, Spinner } from "@chakra-ui/react"

import { useMe } from "lib/hooks/useMe"
import FeedPage from "components/FeedPage"
import HomePage from "components/HomePage"
import { Layout } from "components/Layout"

export default function Home() {
  const { me, loading } = useMe()
  if (loading)
    return (
      <Center h="100vh" w="100%">
        <Spinner />
      </Center>
    )

  if (!me) {
    return <HomePage />
  }
  return <FeedPage />
}

Home.getLayout = (page: React.ReactElement) => <Layout>{page}</Layout>
