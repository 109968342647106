import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { Box, IconButton, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { EventItemFragment } from "lib/graphql"

import { FeedEventTile } from "./FeedEventTile"

interface Props {
  events: EventItemFragment[]
  eventsCount: number
}

export function RecommendedEventsSlider({ events, eventsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 2

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 30,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <Box id="my-keen-slider-recommended-events" className="keen-slider" ref={sliderRef} position="relative">
      {eventsCount > displayCount && (
        <>
          <IconButton
            zIndex={500}
            position="absolute"
            top="85px"
            left={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentIndex === 0}
            width="3rem"
          />
          <IconButton
            zIndex={500}
            position="absolute"
            top="85px"
            right={0}
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            disabled={currentIndex + 1 === eventsCount || currentIndex >= eventsCount}
            width="3rem"
            ml={5}
          />
        </>
      )}
      {events.map((event) => (
        <Box key={`recommended:${event.id}`} cursor="pointer" className="keen-slider__slide">
          <Box display="flex" w="100%">
            <FeedEventTile event={event} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
