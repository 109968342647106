import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { Box, Flex, IconButton, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { PostItemFragment } from "lib/graphql"

import { FeedPostTile } from "./FeedPostTile"

interface Props {
  posts: PostItemFragment[]
  postsCount: number
}

export function RecommendedPostsSlider({ posts, postsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 2

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 30,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <>
      <Box id="my-keen-slider-recommended-posts" className="keen-slider" ref={sliderRef} position="relative">
        {posts.map((post) => (
          <Box key={post.id} cursor="pointer" className="keen-slider__slide">
            <FeedPostTile post={post} isRecommended />
          </Box>
        ))}
      </Box>
      {postsCount > displayCount && (
        <Flex justifyContent="space-between" w="100%" pt={2}>
          <IconButton
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentIndex === 0}
            width="3rem"
          />
          <IconButton
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            disabled={currentIndex === postsCount || currentIndex >= postsCount}
            width="3rem"
            ml={5}
          />
        </Flex>
      )}
    </>
  )
}
